import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormStateService } from '../form-state.service';
import { BusinessDetailsNewComponent } from '../business-details-new/business-details-new.component';
import { InchargeOwnerDetailsComponent } from '../incharge-owner-details/incharge-owner-details.component';
import { ServiceOfferedNewComponent } from '../service-offered/service-offered.component';
import { TechnicianInfoComponent } from '../technician-info/technician-info.component';
import { SubscriptionPlanComponent } from '../subscription-plan/subscription-plan.component';
import { PaymentComponent } from '../payment/payment.component';
import { ServiceProviderComponent } from '../../service-provider.component';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-add-sp',
  templateUrl: './add-sp.component.html',
  styleUrls: ['./add-sp.component.scss'],
})
export class AddSpComponent implements OnInit {
  technicianInfo: any;
  editMode: any;
  @Input() id?: number;
  selectedBusinessType: any;
  selectedServices!: any[];
  constructor(
    private cdr: ChangeDetectorRef,
    private formstate: FormStateService,
    private api: ServiceProviderService,
  ) { }

  ngOnInit(): void {
    this.editMode = !this.id;
    if (!this.editMode) {
      this.serviceProviderById(<number>this.id);
    }
    this.cdr.detectChanges();
  }
  @ViewChild(ServiceProviderComponent)
  serviceProvider!: ServiceProviderComponent;

  @ViewChild(BusinessDetailsNewComponent)
  businessDetailsFormComponent!: BusinessDetailsNewComponent;

  @ViewChild(InchargeOwnerDetailsComponent)
  ownerDetails!: InchargeOwnerDetailsComponent;

  @ViewChild(ServiceOfferedNewComponent)
  serviceOffered!: ServiceOfferedNewComponent;

  @ViewChild(TechnicianInfoComponent)
  technicianDetails!: TechnicianInfoComponent;

  @ViewChild(SubscriptionPlanComponent)
  subscriptionPlan!: SubscriptionPlanComponent;

  @ViewChild(PaymentComponent) payment!: PaymentComponent;

  @Output() updateServiceProviderList = new EventEmitter<void>();

  @Output() closeDialog = new EventEmitter<void>();

  activeSection: string = 'business-details';
  selectedPlan: any = null;
  countryId!: any;

  setActiveSection(section: string): void {
    this.activeSection = section;
    console.log(this.activeSection);
    if (this.activeSection === 'owner-details' && !this.editMode && this.id) {
      this.serviceProviderById(this.id)
    }
    if (this.activeSection === 'service-offered' && !this.editMode && this.id) {
      this.serviceProviderById(this.id)
    }
    if (this.activeSection === 'technician-details' &&
      !this.editMode && this.id) {
      this.serviceProviderById(this.id)
    }
  }

  onUpdateServiceProviderList(): void {
    this.updateServiceProviderList.emit();
  }

  onPlanSelected(plan: any): void {
    this.selectedPlan = plan;
  }

  close() {
    if (this.ownerDetails) {
      this.ownerDetails.resetForm();
    }
    if (this.serviceOffered) {
      this.serviceOffered.resetForm();
    }
    if (this.technicianDetails) {
      this.technicianDetails.resetForm();
    }
    if (this.subscriptionPlan) {
      this.subscriptionPlan.resetForm();
    }

    if (this.businessDetailsFormComponent) {
      this.businessDetailsFormComponent.resetForm();
    }
    this.formstate.clearFormData();
    this.closeDialog.emit();
  }

  setCountryId(countryId: string) {
    this.countryId = countryId;
  }
  serviceProviderById(id: number) {
    this.api.getServiceproviderById(id).subscribe((res: any) => {

      const existingOwnerDetailsData = this.formstate.getFormData('owner-details');

      const ownerDetailsData = {
        ownerName: res.ownerName,
        ownerPhone: res.ownerPhoneNumber,
        ownerEmail: res.ownerEmailAddress,
        ownerAdhaar: res.serviceProviderDocuments?.find((doc: any) => doc.documentTypeId === 7)?.fileId || null,
        inchargeName: res.garageInChargeName,
        inchargePhone: res.garageInChargePhoneNumber,
        inchargeEmail: res.garageInChargeEmailAddress,
        inchargeAdhaar: res.serviceProviderDocuments?.find((doc: any) => doc.documentTypeId === 8)?.fileId || null,
        sponsorName: res.sponsorName || null,
        sponsorPhone: res.sponsorPhoneNumber || null,
        sponsorEmail: res.sponsorEmailAddress || null
      };
      if (!existingOwnerDetailsData){
        // Set the form data for the InchargeOwnerDetailsComponent
        this.formstate.setFormData('owner-details', ownerDetailsData);
      }

      if (this.businessDetailsFormComponent) {
        this.businessDetailsFormComponent.populateFormWithServiceProviderDetails(res);
      }

      if (this.ownerDetails) {
        console.log("Edit owner incharge details");
      }

      if (this.serviceOffered) {
        console.log("Edit Service offered component");
        this.serviceOffered.populateFormWithServiceProviderDetails(res);
      }

      if (this.technicianDetails) {
        console.log("Edit technician details");
        this.technicianDetails.populateFormWithServiceProviderDetails(res);
      }
    });
  }

}
