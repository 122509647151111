import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-service-modal',
  templateUrl: './add-service-modal.component.html',
  styleUrls: ['./add-service-modal.component.scss']
})
export class AddServiceModalComponent {
  @Input() serviceName: string = '';
  @Output() confirmAdd = new EventEmitter<boolean>();

  onConfirm() {
    this.confirmAdd.emit(true);
  }

  onCancel() {
    this.confirmAdd.emit(false);
  }
}
