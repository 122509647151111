<body>
  <div>
    <div class="top-container">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center justify-content-center m-3">
          <i
            class="pi pi-arrow-left"
            style="font-size: 1rem; cursor: pointer"
            routerLink="/service-provider"
          ></i>
        </div>
        <!-- Page header -->
        <div
          class="flex align-items-center justify-content-center m-2"
          *ngIf="status === 'Submitted'"
        >
          <span class="m-1">
            <button
              class="btn-1 btn-warning-1 outlined-1 small raised"
              (click)="reject()"
            >
              Reject
            </button>
          </span>
          <span class="m-1">
            <button
              class="btn-1 btn-warning-1 outlined-1 small raised"
              (click)="openEditModal(serviceprovider?.serviceProviderId)"
            >
              Edit
            </button>
          </span>
          <span class="m-1">
            <div class="container">
              <button
                class="btn-1 btn-contrast-1 outlined small raised"
                (click)="openReviewModal()"
              >
                Mark as review
              </button>
            </div>
          </span>
          <span class="m-1">
            <button
              class="btn-1 btn-seconary-warning small raised"
              (click)="approve()"
            >
              Approve
            </button>
          </span>
        </div>
      </div>
      <!-- Section -->
      <div class="card-top">
        <!-- Map serviceProviderGalleries id here -->
        <img [src]="imgPath" class="image" />
        <div class="text" style="padding-left: 40px">
          <!-- Name of the service provider -->
          <div class="main-text">
            {{ truncatedServiceProviderName }}
          </div>
          <!-- address -->
          <div class="secondary-text" style="padding-top: 12px">
            {{ address }}
          </div>
          <!-- view Address proof -->
          <div>
            <div class="flex flex-row flex-wrap">
              <div class="flex align-items-center justify-content-center">
                <i
                  class="pi pi-paperclip"
                  style="font-size: 12px; color: #026aa2"
                ></i>
              </div>
              <!-- //Map address proof document here -->
              <!-- Map serviceProviderDocuments
                                     which has documentTypeId 5 -->
              <div
                class="flex align-items-center justify-content-center m-1"
                style="
                  font-size: 14px;
                  color: #026aa2;
                  line-height: 20px;
                  cursor: pointer;
                "
                (click)="viewAddressProof()"
              >
                View Address proof
              </div>
            </div>
          </div>
          <div style="width: 497px">
            <div class="row-container">
              <div class="business-details">
                <div class="owner">Business Owner</div>
                <div class="user-info">
                  <div class="icon-circle">
                    <i class="user-icon pi pi-user" style="color: #708090"></i>
                  </div>
                  <div class="username ml-2">
                    <p class="name">{{ name }}</p>
                    <p class="email">{{ ownerPhoneNumber }}</p>
                  </div>
                </div>
                <div style="cursor: pointer">
                  <div
                    (click)="viewOwnerIdProof()"
                    style="width: 130px; margin-top: 12px"
                    class="flex justify-content-start flex-wrap"
                  >
                    <i
                      class="flex align-items-center justify-content-center pi pi-paperclip mr-1"
                      style="font-size: 14px; color: #026aa2"
                    >
                    </i>
                    <!-- Map owner Id proof here from
                                             serviceProviderDocuments which ha documentTypeId 7 -->
                    <div
                      class="flex align-items-center justify-content-center"
                      style="font-size: 14px; color: #026aa2; line-height: 20px"
                    >
                      View ID proof
                    </div>
                  </div>
                </div>
              </div>
              <div class="divider"></div>
              <div style="margin-top: 65px; margin-left: 52px">
                <div class="owner">Incharge Details</div>
                <div class="user-info">
                  <div class="icon-circle">
                    <i class="user-icon pi pi-user" style="color: #708090"></i>
                  </div>
                  <div class="username ml-2">
                    <p class="name">{{ garageInChargeName }}</p>
                    <p class="email">{{ garageInChargePhoneNumber }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div style="cursor: pointer" (click)="viewInchargeIdProof()">
              <div
                style="width: 130px; margin-top: 5px; margin-left: 288px"
                class="flex justify-content-start flex-wrap"
              >
                <i
                  class="flex align-items-center justify-content-center pi pi-paperclip mr-1"
                  style="font-size: 14px; color: #026aa2"
                >
                </i>
                <!-- Map garage incharge documents from
                                     serviceProviderDocuments which has documentTypeId 8-->
                <div
                  class="flex align-items-center justify-content-center"
                  style="font-size: 14px; color: #026aa2; line-height: 20px"
                >
                  View ID proof
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-content-center flex-wrap p-1">
            <span
              class="tag"
              [ngClass]="{
                submitted: status === 'Submitted',
                approved: status === 'Accepted',
                inactive: status === 'Inactive',
                rejected: status === 'Rejected'
              }"
              >{{ status }}</span
            >
          </div>

          <div class="history" (click)="displayHistory()">
            <p-button
              icon="pi pi-history"
              label="History"
              [rounded]="true"
              [text]="true"
              size="small"
            />
          </div>
          <p-dialog [(visible)]="visibleHistory" header="History">
            <p-timeline [value]="events">
              <ng-template pTemplate="content" let-event>
                <small class="p-text-secondary">{{
                  event.createdBy?.name || "ABC"
                }}</small
                ><br />
                <small class="p-text-secondary">{{
                  event.createdOn | date
                }}</small>
              </ng-template>
              <ng-template pTemplate="opposite" let-event>
                {{ event.status }}
              </ng-template>
              <ng-template pTemplate="opposite" let-event>
                {{ event.status }}
              </ng-template>
            </p-timeline>
          </p-dialog>
        </div>
      </div>
    </div>
    <div class="main-part">
      <div style="padding-left: 49px">
        <p style="font-weight: 500; font-size: 18px; margin-bottom: 40px">
          Business details
        </p>
        <div class="flex justify-content-start flex-wrap">
          <div class="">
            <div style="width: 225px">
              <p class="business-type-label">Business type</p>
              <p class="business-type-value">{{ businessType }}</p>
            </div>
            <div class="business-info">
              <p class="business-type-label">Submited by</p>
              <p class="business-type-value" pTooltip="{{ applicantEmailAddress }}" tooltipPosition="top">
                {{ submittedBy }}({{ truncatedEmail }})
              </p>
            </div>
          </div>
          <div style="width: 225px; padding-left: 52px">
            <div>
              <p class="business-type-label">GST Number</p>
              <p class="business-type-value">{{ gst }}</p>
            </div>
            <div class="business-info">
              <p class="business-type-label">Submited on</p>
              <p class="business-type-value">{{ date | date }}</p>
            </div>
          </div>
          <div style="width: 225px; padding-left: 52px">
            <div>
              <p class="business-type-label">Year of commencement</p>
              <div style="cursor: pointer">
                <div class="flex flex-row flex-wrap">
                  <p class="business-type-value">{{ commencementYear }}</p>
                  <div
                    class="flex align-items-center justify-content-center ml-2"
                  >
                    <i
                      class="pi pi-paperclip"
                      style="font-size: 14px; color: #026aa2"
                    >
                    </i>
                  </div>
                  <!-- Certificate of incorporation documents here from serviceProviderDocuments which has documentTypeId 6-->
                  <div
                    class="flex align-items-center justify-content-center ml-1"
                    style="font-size: 14px; color: #026aa2; line-height: 20px"
                    (click)="viewCOIProof()"
                  >
                    View proof
                  </div>
                </div>
              </div>
            </div>
            <div class="business-info">
              <p class="business-type-label">Application ID</p>
              <p class="business-type-value">{{ applicationId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-part">
      <div style="padding-left: 49px">
        <p style="font-weight: 500; font-size: 18px; margin-bottom: 40px">
          Subscription plan
        </p>
        <div class="flex justify-content-start flex-wrap">
          <div style="width: 225px">
            <div>
              <p class="business-type-label">Plan name</p>
              <p class="business-type-value">{{ planName }}</p>
            </div>
            <div class="business-info">
              <p class="business-type-label">Add ons</p>
              <p class="business-type-value">---</p>
            </div>
          </div>
          <div style="width: 225px; padding-left: 52px">
            <div>
              <p class="business-type-label">Amount paid</p>
              <p class="business-type-value">{{ paidAmount }}</p>
            </div>
            <div class="business-info"></div>
          </div>
          <div style="width: 225px; padding-left: 52px">
            <div>
              <p class="business-type-label">Invoice</p>
              <div style="cursor: pointer">
                <div class="flex flex-row flex-wrap">
                  <div class="flex align-items-center justify-content-center">
                    <i
                      class="pi pi-download"
                      style="font-size: 14px; color: #026aa2"
                    >
                    </i>
                  </div>
                  <!-- and user can download the page too clicking download-->
                  <div
                    class="flex align-items-center justify-content-center ml-1"
                    style="font-size: 14px; color: #026aa2; line-height: 20px"
                  >
                    Download
                  </div>
                </div>
              </div>
            </div>
            <div class="business-info"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-part">
      <div style="padding-left: 49px">
        <p style="font-weight: 500; font-size: 18px; margin-bottom: 40px">
          Services
        </p>
        <div class="service-container">
          <div *ngFor="let item of displayedServices">
            <span class="icon-circle-1">
              <i
                class="pi pi-check"
                style="color: #12b76a; font-size: 9px; font-weight: 900"
              ></i>
            </span>
            <span class="service-name m-1">{{ item }}</span>
          </div>
        </div>
        <div
          *ngIf="serviceName && serviceName.length > 4"
          style="margin-top: 10px"
        >
          <button class="btn-toggle" (click)="toggleShowAllServices()">
            {{ showAllServices ? "Show Less" : "Show More" }}
          </button>
        </div>
      </div>
    </div>

    <div class="main-part">
      <div style="padding-left: 49px">
        <p style="font-weight: 500; font-size: 16px; margin-bottom: 40px">
          Employee details ({{ getTotalTechnicians() }})
        </p>
        <div class="flex justify-content-start flex-wrap">
          <div
            *ngFor="let technician of serviceProviderTechnicians"
            style="width: 245px"
          >
            <div>
              <p class="business-type-label">{{ technician.technicianName }}</p>
              <p class="employee-value">{{ technician.noOfTechnician }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-dialog [(visible)]="reviewModal">
      <ng-template pTemplate="headless">
        <app-review-modal
          [resourceTypeLabel]="'Service provider'"
          (closeModal)="closeReviewModal()"
          [resourceType]="'SERVICEPROVIDER'"
          (onReviewSuccess)="markForReview()"
        ></app-review-modal>
      </ng-template>
    </p-dialog>

    <div>
      <p-dialog [(visible)]="visible">
        <ng-template pTemplate="headless">
          <app-custom-dialog
            (closeModal)="closeApproveModal()"
            (refreshList)="refreshDetails()"
            [resourceType]="'SERVICEPROVIDER'"
          ></app-custom-dialog>
        </ng-template>
      </p-dialog>
    </div>
    <div>
      <p-dialog [(visible)]="visibleReject">
        <ng-template pTemplate="headless">
          <app-reject-modal
            [resourceType]="'SERVICEPROVIDER'"
            (closeModal)="closeRejectModal()"
            (refreshList)="getServiceProviderDetailsById()"
            (updateServiceProviderList)="closeRejectModal()"
          ></app-reject-modal>
        </ng-template>
      </p-dialog>
    </div>
    <p-dialog
      [(visible)]="visibleViewer"
      [maximizable]="true"
      [modal]="true"
      [style]="{ width: '80vw', height: '80vh' }"
      [closable]="true"
      (onHide)="closeViewer()"
    >
      <ng-template pTemplate="content">
        <pdf-viewer
          *ngIf="selectedDocumentUrl && isPdf(selectedDocumentUrl)"
          [src]="selectedDocumentUrl"
          [render-text]="true"
          [original-size]="false"
          style="width: 100%; height: 100%"
        >
        </pdf-viewer>
        <div class="flex justify-content-center flex-wrap">
          <img
            *ngIf="selectedDocumentUrl && !isPdf(selectedDocumentUrl)"
            [src]="selectedDocumentUrl"
            alt="Document Image"
            style="max-width: 100%; max-height: 70vh"
          />
        </div>
      </ng-template>
    </p-dialog>
  </div>
</body>
<div>
  <app-add-sp
    *ngIf="showModal"
    [id]="idToEdit"
    (closeDialog)="showModalW()"
  ></app-add-sp>
</div>
