<div style="color: #101828;font-size: 20px;font-weight: 600; margin-bottom: 43px;">
    Technician details
</div>

<form [formGroup]="form" (ngSubmit)="serviceProviderId ? update() : onSubmit()">
    <div class="total-technicians">
        Total technicians: {{ totalTechnicians }}
    </div>

    <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>

    <button type="submit" class="btn btn-primary">
        {{ serviceProviderId ? 'Update' : 'Next' }}
    </button>
</form>
<!-- Loading overlay -->
<div *ngIf="isLoading" class="overlay">
    <div class="spinner">
        <div class="spinner-bar"></div>
    </div>
</div>