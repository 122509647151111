import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormStateService } from '../form-state.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  @Output() planSelected = new EventEmitter<any>();
  @Output() nextSection = new EventEmitter<void>();

  selectedPlan: string | null = null;
  subscriptionForm: FormGroup;
  receivedObject: any;

  plans = [
    {
      name: 'Basic Plan',
      price: 100.0,
      description: 'One time fee, No recurring payments',
      features: ['Access to all basic features'],
      unavailableFeatures: ['Diagnostic tool'],
    },
    {
      name: 'Standard Plan',
      price: 200.0,
      description: 'One time fee, No recurring payments',
      features: ['Access to all basic features', 'Diagnostic Tool'],
      unavailableFeatures: [],
    },
    {
      name: 'Premium Plan',
      price: 600.0,
      description: 'One time fee, No recurring payments',
      features: ['Access to all basic features', 'Diagnostic Tool'],
      unavailableFeatures: [],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private formStateService: FormStateService
  ) {
    this.subscriptionForm = this.fb.group({
      selectedPlan: [''],
    });
  }

  ngOnInit(): void {
    const savedPlan = this.formStateService.getFormData('selectedPlan');
    if (savedPlan) {
      this.selectedPlan = savedPlan;
      this.subscriptionForm.get('selectedPlan')?.setValue(savedPlan);
    }
    this.formStateService.spDetails.subscribe((object) => {
      this.receivedObject = object;
      this.populateFormWithServiceProviderDetails(this.receivedObject);
      console.log(this.receivedObject);
    });
  }
  populateFormWithServiceProviderDetails(res: any) {}
  selectPlan(plan: string): void {
    console.log('Selecting Plan:', plan);
    this.selectedPlan = plan;
    this.subscriptionForm.get('selectedPlan')?.setValue(plan);
    this.planSelected.emit(this.plans.find((p) => p.name === plan));
    this.formStateService.setFormData('selectedPlan', plan);
  }
  resetForm() {
    console.log('Resetting Subscription Plan Form');
    this.selectedPlan = null;
    this.formStateService.setFormData('selectedPlan', '');
    console.log('Form after reset:', this.selectedPlan);
    this.subscriptionForm.reset();
  }

  onSubmit(): void {
    if (this.selectedPlan) {
      console.log('Selected Plan:', this.selectedPlan);
      this.planSelected.emit(
        this.plans.find((p) => p.name === this.selectedPlan)
      );
      this.nextSection.emit();
    } else {
      console.log('No plan selected');
    }
  }
}
