<div class="subscription-container">
  <div class="subscription-header" style="margin-bottom: 43px;">
    <span class="custom-header">Subscription Plan</span>
  </div>

  <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">
    <div class="subscription-plans">
      <div 
        class="plan-card" 
        [class.selected]="selectedPlan === 'Basic Plan'" 
        (click)="selectPlan('Basic Plan')"
      >
        <p class="plan-title">Basic Plan</p>
        <span class="plan-description">One time fee, No recurring payments</span>
        <p class="plan-price">$100.00</p>
        <div class="plan-features">
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check"></i>
            </div>
            <span class="feature-text">Access to all basic features</span>
          </div>
          <div class="feature">
            <div class="feature-icon feature-unavailable">
              <i class="pi pi-times"></i>
            </div>
            <div class="feature-text">
              <span>Diagnostic tool</span>
              <span class="feature-note">(Eliminates the manual process in troubleshooting the issue. Know more)</span>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="plan-card-2 recommended" 
        [class.selected]="selectedPlan === 'Standard Plan'" 
        (click)="selectPlan('Standard Plan')"
      >
        <div class="recommended-badge" style="margin-bottom: 8px;">
          <i class="pi pi-circle-fill"></i> Recommended
        </div>
        <p class="plan-title">Standard Plan</p>
        <span class="plan-description">One time fee, No recurring payments</span>
        <p class="plan-price">$200.00</p>
        <div class="plan-features">
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check"></i>
            </div>
            <span class="feature-text">Access to all basic features</span>
          </div>
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check"></i>
            </div>
            <div class="feature-text">
              <span>Diagnostic Tool.</span>
              <span class="feature-note">(Eliminates the manual process in troubleshooting the issue. Know more)</span>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="plan-card" 
        [class.selected]="selectedPlan === 'Premium Plan'" 
        (click)="selectPlan('Premium Plan')"
      >
        <p class="plan-title">Premium Plan</p>
        <span class="plan-description">One time fee, No recurring payments</span>
        <p class="plan-price">$600.00</p>
        <div class="plan-features">
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check"></i>
            </div>
            <span class="feature-text">Access to all basic features</span>
          </div>
          <div class="feature">
            <div class="feature-icon feature-available">
              <i class="pi pi-check"></i>
            </div>
            <div class="feature-text">
              <span>Diagnostic Tool.</span>
              <span class="feature-note">(Eliminates the manual process in troubleshooting the issue. Know more)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="!subscriptionForm.valid">Next</button>
  </form>
</div>
