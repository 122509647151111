import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';

@Component({
  selector: 'app-business-details-new',
  templateUrl: './business-details-new.component.html',
  styleUrls: ['./business-details-new.component.scss'],
})
export class BusinessDetailsNewComponent
  implements OnInit, OnDestroy {
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    name: '',
    applicantName: '',
    applicantEmailAddress: '',
    address: '',
    stateId: '',
    countryId: '',
    yearOfEstablishment: '',
    gstNumber: '',
    vatNumber: '',
    city: '',
    zip: '',
    addressProof: '',
    certificateOfIncorporation: '',
    galleryIds: '',
    businessTypeId: '',
    averageCustomer: '',
  };
  fields: FormlyFieldConfig[] = [];
  selectedBusinessType!: string;
  @Output() nextSection = new EventEmitter<void>();
  @Input() countryId!: string;
  @Output() clearForm = new EventEmitter<void>();
  @Output() editServiceProviderList: EventEmitter<void> =
    new EventEmitter<void>();
  businessTypes = [];
  receivedObject: any;
  constructor(
    private fb: FormBuilder,
    private formStateService1: FormStateService,
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef
  ) { }
  countryName!: string;

  ngOnInit() {
    this.loadFormData();

    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      this.countryName = tokenDetails.country?.name;

      if (this.countryId) {
        console.log('CountryId from localStorage:', this.countryId);
        this.model.countryId = this.countryId;
        this.initializeFields();
        this.form.patchValue({ countryId: this.countryId });
      }
    }
    // Subscribe to the selected business type from the FormStateService
    this.formStateService1.selectedBusinessType$.subscribe(
      (businessType: any) => {
        if (businessType) {
          this.selectedBusinessType = businessType;
          this.form.patchValue({
            businessTypeId: businessType.businessTypeId,
          });

          this.model.businessTypeId = businessType.businessTypeId;
        }
      }
    );
    this.api.getBusinessTypeList('').subscribe((businessTypes: any) => {
      this.businessTypes = businessTypes || [];
      this.formStateService1.setBusinessTypes(businessTypes);
      this.initializeFields();
    });
  }

  ngOnDestroy() {
    this.saveFormData();
  }

  initializeFields() {
    const businessTypeOptions = (this.businessTypes || []).map((x: any) => ({
      value: x.businessTypeId,
      label: x.name,
    }
    )
    );
    this.fields = [
      {
        fieldGroupClassName: 'p-fluid p-formgrid p-grid',
        fieldGroup: [
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col',
                type: 'input',
                key: 'name',
                templateOptions: {
                  label: 'Name of the Business',
                  placeholder: 'Enter Name',
                  required: true,
                },
              },
              {
                className: 'field col',
                key: 'yearOfEstablishment',
                type: 'select',
                templateOptions: {
                  placeholder: 'Select',
                  label: 'Years Of Commencement',
                  options: [
                    { label: '2024', value: '2024' },
                    { label: '2023', value: '2023' },
                    { label: '2022', value: '2022' },
                  ],
                },
                defaultValue: '',
              },
            ],
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'businessTypeId',
                type: 'select',
                templateOptions: {
                  label: 'Business Type',
                  placeholder: 'Select business type',
                  options: businessTypeOptions,
                  required: true,
                },
                hooks: {
                  onInit: (field) => {
                    field.formControl?.valueChanges.subscribe((value: any) => {
                      this.onBusinessTypeChange(value);
                    });
                  },
                },
              },
              {
                className: 'field col',
                key: 'averageCustomer',
                type: 'select',
                props: {
                  placeholder: 'Select',
                  label: 'Average Customers',
                  options: [
                    { label: '0-100', value: '0-100' },
                    { label: '100-500', value: '100-500' },
                    { label: '500-1000', value: '500-1000' },
                    { label: 'More than 1000', value: 'More than 1000' },
                  ],
                },
              },
            ],
          },
          {
            template: `<div class="mt-1"></div>`,
          },
          {
            className: 'field col-12',
            template: '<h3>Address</h3>',
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col',
                key: 'countryId',
                type: 'select',
                templateOptions: {
                  disabled: true,
                  options: this.api.getCountry(''),
                  placeholder: 'Select a country',
                  valueProp: 'countryId',
                  labelProp: 'name',
                  label: 'Country',
                },
                hooks: {
                  onInit: (field) => {
                    if (this.countryId) {
                      field.formControl?.setValue(this.countryId);
                      field.templateOptions!.disabled = true;
                    }
                  },
                },
              },
              {
                className: 'field col',
                key: 'stateId',
                type: 'select',
                templateOptions: {
                  placeholder: 'Select State',
                  options: this.api.getStates(this.countryId),
                  valueProp: 'stateId',
                  labelProp: 'name',
                  label: 'State',
                  required: true,
                },
              },
            ],
          },
          {
            template: `<div class="mt-1"></div>`,
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col-6',
                key: 'city',
                type: 'input',
                templateOptions: {
                  label: 'City',
                  placeholder: 'Enter City',
                  required: true,
                  hideLabel: true,
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'field grid p-fluid',
            fieldGroup: [
              {
                className: 'field col',
                key: 'address',
                type: 'input',
                templateOptions: {
                  label: 'Address',
                  placeholder: 'Enter Address',
                  required: true,
                  hideLabel: true,
                },
              },
              {
                className: 'field col',
                key: 'zip',
                type: 'input',
                templateOptions: {
                  label: 'Zip',
                  placeholder: 'Enter Pin Code',
                  required: true,
                  hideLabel: true,
                },
                validators: {
                  zip: {
                    expression: (c: AbstractControl) =>
                      /^[1-9][0-9]{5}$/.test(c.value),
                    message: (error: any, field: FormlyFieldConfig) =>
                      `${field.formControl?.value} is not a valid ZIP code`,
                  },
                },
              },
            ],
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            // documenttype = addressproof
            key: 'addressProof',
            type: 'file',
            templateOptions: {
              accept: '.pdf,.doc,.docx,.png,.jpg',
              placeholder: 'Upload address proof',
              required: true,
            },

            validators: {
              validation: [Validators.required],
            },
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            className: 'field col-12',
            template: '<h3>Others</h3>',
          },
          {
            type: 'input',
            key: 'gstNumber',
            templateOptions: {
              label: 'GST Number',
              placeholder: 'Enter GST Number',
              required: true,
            },
            expressions: {
              hide: 'model.countryId!==1',
            },
            validators: {
              gst: {
                expression: (c: AbstractControl) =>
                  /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/.test(
                    c.value
                  ),
                message: (error: any, field: FormlyFieldConfig) =>
                  `${field.formControl?.value} is not a valid GST Number`,
              },
            },
          },
          {
            type: 'input',
            key: 'vatNumber',
            templateOptions: {
              label: 'VAT Number',
              placeholder: 'Enter VAT Number',
              required: true,
            },
            expressions: {
              hide: 'model.countryId!==2',
            },
            validators: {
              vat: {
                expression: (c: AbstractControl) => /^\d{15}$/.test(c.value),
                message: (error: any, field: FormlyFieldConfig) =>
                  `${field.formControl?.value} is not a valid VAT Number`,
              },
            },
          },
          {
            template: `<div class="mt-2"></div>`,
          },
          {
            template: `<div class="mt-3"><strong>Front view photo of business/shop</strong></div>`,
          },
          {
            key: 'galleryIds',
            type: 'multifile',
            templateOptions: {
              placeholder: 'Upload',
              required: true,
            },
          },
          {
            template: `<div class="mt-3"><strong>Certificate of incorporation</strong></div>`,
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            key: 'commercialRegistrationNumber',
            type: 'input',
            templateOptions: {
              placeholder: 'Enter Commercial Registration Number',
              required: true,
            },
            expressions: {
              hide: 'model.countryId!==2',
            },
          },
          {
            template: `<div class="mt-3"></div>`,
          },
          {
            // dType = certificateofincorporation
            key: 'certificateOfIncorporation',
            type: 'file',
            templateOptions: {
              placeholder: 'Upload',
              required: true,
            },
          },
          {
            template: `<div class="mt-3"><strong>Application submitted by</strong></div>`,
          },
          {
            fieldGroupClassName: 'field grid p-fluid mt-3',
            fieldGroup: [
              {
                className: 'field col mt-2',
                key: 'applicantName',
                type: 'input',
                templateOptions: {
                  label: 'Name',
                  placeholder: 'Enter Name',
                  required: true,
                },
              },
              {
                className: 'field col mt-2',
                key: 'applicantEmailAddress',
                type: 'input',
                templateOptions: {
                  label: 'Email',
                  placeholder: 'Enter Email',
                  required: true,
                },
                validators: {
                  email: {
                    expression: (c: AbstractControl) =>
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        c.value
                      ),
                    message: (error: any, field: FormlyFieldConfig) =>
                      `${field.formControl?.value} is not a valid Email Address`,
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  updateStateOptions(country: string) {
    const stateField = this.findFieldByKey(this.fields, 'stateId');
    if (stateField) {
      this.api.getStates(country).subscribe((states: any) => {
        if (stateField.templateOptions) {
          stateField.templateOptions.options = states;
          console.log('Updated States:', states); // Debug log
        }
      });
    }
  }

  onCountryChange(event: any) {
    const country = event.value;
    this.updateFieldsVisibility(country);

    const stateField = this.findFieldByKey(this.fields, 'stateId');
    if (stateField) {
      if (country) {
        stateField.templateOptions!.disabled = false;
        this.updateStateOptions(country);
      }
    }
  }

  updateFieldsVisibility(country: string) {
    console.log('Current countryId:', country);

    const gstNumberField = this.findFieldByKey(this.fields, 'gstNumber');
    const vatNumberField = this.findFieldByKey(this.fields, 'vatNumber');
    const commercialRegField = this.findFieldByKey(
      this.fields,
      'commercialRegistrationNumber'
    );
    const certificateField = this.findFieldByKey(
      this.fields,
      'certificateOfIncorporation'
    );

    if (gstNumberField) {
      gstNumberField.hideExpression = () => country !== '1'; // Show GST field if country is India ('1')
    }
    if (vatNumberField) {
      vatNumberField.hideExpression = () => country !== '2'; // Show VAT field if country is Oman ('2')
    }
    if (commercialRegField) {
      commercialRegField.hideExpression = () => country !== '2';
    }
    if (certificateField) {
      certificateField.hideExpression = () => country !== '2';
    }
  }

  findFieldByKey(
    fields: FormlyFieldConfig[],
    key: string
  ): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        const nestedField = this.findFieldByKey(
          field.fieldGroup as FormlyFieldConfig[],
          key
        );
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  loadFormData() {
    const savedData = this.formStateService1.getFormData('business-details');
    if (savedData) {
      console.log('Saved Data:', savedData);
      this.model = savedData;
      this.form.patchValue(savedData);
    }
  }

  clearFormData() {
    this.formStateService1.clearFormData();
    this.form.reset();
    this.model = {};
    this.initializeFields();
    console.log('BusinessDetailsNewComponent: Form data cleared.');
  }
  resetForm() {
    this.form.reset();
    this.model = {};
    this.initializeFields();
  }

  saveFormData() {
    const formData = { ...this.model };
    this.formStateService1.setFormData('business-details', formData);
    console.log(formData);
  }

  onBusinessTypeChange(value: any) {
    const businessTypeField = this.findFieldByKey(
      this.fields,
      'businessTypeId'
    );
    if (businessTypeField) {
      this.api.getBusinessTypeList('').subscribe((businessTypes: any[]) => {
        const selectedType = businessTypes.find(
          (type) => type.businessTypeId === value
        );
        if (selectedType) {
          this.formStateService1.setSelectedBusinessType(selectedType);
        }
      });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const transformedData = this.transformFormData(this.form.value);
      this.formStateService1.setBusinessDetails(transformedData);
      console.log(transformedData);
      this.nextSection.emit();
      this.cdr.detectChanges();
    }
  }

  transformFormData(formData: any): any {
    return {
      name: formData.name,
      applicantName: formData.applicantName,
      applicantEmailAddress: formData.applicantEmailAddress,
      address: formData.address,
      stateId: formData.stateId,
      countryId: this.countryId,
      yearOfEstablishment: formData.yearOfEstablishment,
      gstNumber: formData.gstNumber || null,
      vatNumber: formData.vatNumber || null,
      city: formData.city,
      zip: formData.zip,
      averageCustomer: formData.averageCustomer,
      documents: [
        {
          documentTypeId: 5,
          documentNumber: '',
          fileId: formData.addressProof,
        },
        {
          documentTypeId: 6,
          documentNumber: '',
          fileId: formData.certificateOfIncorporation,
        },
      ],
      galleryIds: formData.galleryIds,
      businessTypeId: formData.businessTypeId,
    };
  }

  populateFormWithServiceProviderDetails(res: any) {
    // Clear the existing galleryIds to avoid duplicates
    this.model.galleryIds = [];
  
    this.model.name = res.name || '';
    this.model.applicantName = res.applicantName || '';
    this.model.applicantEmailAddress = res.applicantEmailAddress || '';
    this.model.address = res.address || '';
    this.model.stateId = res.state?.stateId || '';
    this.model.countryId = res.country?.countryId || '';
    this.model.yearOfEstablishment = res.yearOfEstablishment?.toString();
    this.model.gstNumber = res.gstNumber || '';
    this.model.vatNumber = res.vatNumber || '';
    this.model.city = res.city || '';
    this.model.zip = res.zip || '';
    this.model.averageCustomer = res?.averageCustomer || '';
  
    // Ensure no duplicates by using Set to filter unique fileIds
    const uniqueGalleryIds = new Set(
      res.serviceProviderGalleries?.map((gallery: any) => gallery.fileId)
    );
    this.model.galleryIds = Array.from(uniqueGalleryIds);
  
    this.model.businessTypeId = res.businessType?.businessTypeId || '';
  
    this.model.addressProof = res.serviceProviderDocuments?.find(
      (doc: any) => doc.documentTypeId === 5
    )?.fileId;
  
    this.model.certificateOfIncorporation = res.serviceProviderDocuments?.find(
      (doc: any) => doc.documentTypeId === 6
    )?.fileId;
  
    // Patch the updated model to the form
    this.form.patchValue(this.model);
    this.cdr.detectChanges();
    this.formStateService1.setFormData('business-details', this.model);
  }
  
}
