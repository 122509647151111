<div
  style="color: #101828; font-size: 20px; font-weight: 600; margin-bottom: 43px"
>
  Add offered services details
</div>
<div class="dropdown-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div
      class="dropdown flex justify-content-between"
      style="margin-bottom: 24px; height: 40px; width: 100%"
    >
      <div class="flex">
        <div class="m-2">
          <label for="serviceCategory" style="font-size: 16px; font-weight: 600"
            >Service lists for:</label
          >
        </div>
        <div class="focus:border-primary">
          <p-dropdown
            id="serviceCategory"
            [options]="serviceLists"
            optionLabel="label"
            dataKey="value"
            placeholder="Select a category"
            formControlName="selectedCategory"
          >
          </p-dropdown>
        </div>
      </div>
      <div>
        <div class="w-full focus:border-primary">
          <input
            type="text"
            pInputText
            placeholder="Search..."
            class="search-box"
            [value]="searchQuery" 
            (input)="onSearchChange($event)"
          />
        </div>
      </div>
    </div>

    <formly-form
      [form]="form"
      [fields]="fields"
      [options]="options"
      [model]="model"
    ></formly-form>
    <button type="submit" class="btn btn-primary">Next</button>
  </form>
</div>

<app-add-service-modal
  *ngIf="showModal"
  [serviceName]="serviceNameToAdd"
  (confirmAdd)="onConfirmAddService($event)"
></app-add-service-modal>
