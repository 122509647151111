import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStateService } from '../form-state.service';
import { ServiceProviderService } from '../../../../Service/Service-providers/service-provider.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-incharge-owner-details',
  templateUrl: './incharge-owner-details.component.html',
  styleUrls: ['./incharge-owner-details.component.scss'],
})
export class InchargeOwnerDetailsComponent implements OnInit {
  form = new FormGroup({});
  // model: any = {};
  model: any = {
    ownerName: '',
    ownerPhone: '',
    ownerEmail: '',
    ownerAdhaar: '',
    inchargeName: '',
    inchargePhone: '',
    inchargeEmail: '',
    inchargeAdhaar: '',
    sponsorName: '',
    sponsorPhone: '',
    sponsorEmail: '',
    sponsorId: '',
    inchargeIdProof: '',
  };
  fields: FormlyFieldConfig[] = [];
  @Output() nextSection = new EventEmitter<void>();
  receivedObject: any;
  isFormPopulated = false;

  constructor(
    private formStateService: FormStateService,
    private api: ServiceProviderService,
    private cdr: ChangeDetectorRef,
    private formStateService1: FormStateService
  ) { }

  @Input() countryId!: string;

  ngOnInit() {
    // issue is with the loadFormData(), and as this is the child compoent loadFormData
    this.loadFormData();
    const tokenDetailsString = localStorage.getItem('tokenDetails');
    if (tokenDetailsString) {
      const tokenDetails = JSON.parse(tokenDetailsString);
      this.countryId = tokenDetails.country?.countryId;
      this.model.countryId = this.countryId;
    }
    this.initializeFields();
  }

  ngOnDestroy() {
    this.saveFormData();
  }
  resetForm() {
    this.form.reset();
    this.model = {};
  }

  initializeFields() {
    if (this.countryId == '1') {
      this.fields = [
        {
          fieldGroupClassName: 'p-fluid p-formgrid p-grid',
          fieldGroup: [
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-5',
                  key: 'ownerName',
                  type: 'input',
                  templateOptions: {
                    label: 'Name of the Owner',
                    placeholder: 'Enter name',
                    required: true,
                  },
                  hideExpression: () => this.model.countryId === '2',
                },
                {
                  className: 'field col-5',
                  key: 'ownerPhone',
                  type: 'input',
                  templateOptions: {
                    label: 'Phone number',
                    placeholder: '+91 1234567890',
                    required: true,
                    hideRequiredMarker: true,
                  },
                  validators: {
                    validation: [
                      Validators.required,
                      Validators.pattern(/^\d{10}$/),
                    ],
                  },
                  validation: {
                    messages: {
                      required: 'Phone number is required',
                      pattern: 'Phone number must be exactly 10 digits',
                    },
                  },
                },
              ],
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'ownerEmail',
                  type: 'input',
                  templateOptions: {
                    label: 'Email',
                    placeholder: 'Enter email id',
                    required: true,
                    type: 'email',
                  },
                  validators: {
                    email: {
                      expression: (c: AbstractControl) =>
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          c.value
                        ),
                      message: (error: any, field: FormlyFieldConfig) =>
                        `${field.formControl?.value} is not a valid email address`,
                    },
                  },
                },
              ],
            },
            {
              className: 'field col-12',
              key: 'ownerAdhaar',
              type: 'file',
              templateOptions: {
                placeholder: 'Upload Adhaar',
                required: true,
                accept: '.pdf,.jpg,.png',
                description: 'Supported file formats: pdf, jpg, png',
              },
            },

            {
              className: 'field col-12',
              template: '<h3>Garage Incharge</h3>',
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'inchargeName',
                  type: 'input',
                  templateOptions: {
                    label: 'Name of the Garage incharge',
                    placeholder: 'Enter name',
                    required: true,
                  },
                },
                {
                  className: 'field col-6',
                  key: 'inchargePhone',
                  type: 'input',
                  templateOptions: {
                    label: 'Phone number',
                    placeholder: 'Enter phone number',
                    required: true,
                    type: 'tel',
                  },
                  validators: {
                    validation: [
                      Validators.required,
                      Validators.pattern(/^\d{10}$/),
                    ],
                  },
                  validation: {
                    messages: {
                      required: 'Phone number is required',
                      pattern: 'Phone number must be exactly 10 digits',
                    },
                  },
                },
              ],
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'inchargeEmail',
                  type: 'input',
                  templateOptions: {
                    label: 'Email',
                    placeholder: 'Enter email id',
                    required: true,
                    type: 'email',
                  },
                  validators: {
                    email: {
                      expression: (c: AbstractControl) =>
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          c.value
                        ),
                      message: (error: any, field: FormlyFieldConfig) =>
                        `${field.formControl?.value} is not a valid email address`,
                    },
                  },
                },
              ],
            },
            {
              className: 'field col-12',
              key: 'inchargeAdhaar',
              type: 'file',
              templateOptions: {
                placeholder: 'Upload Adhaar',
                required: true,
                accept: '.pdf,.jpg,.png',
                description: 'Supported file formats: pdf, jpg, png',
              },
            },
          ],
        },
      ];
    } else {
      this.fields = [
        {
          fieldGroupClassName: 'p-fluid p-formgrid p-grid',
          fieldGroup: [
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col',
                  key: 'sponsorName',
                  type: 'input',
                  templateOptions: {
                    label: 'Name of the Sponsor',
                    placeholder: 'Enter name',
                    required: true,
                  },
                },
                {
                  className: 'field col',
                  key: 'sponsorPhone',
                  type: 'input',
                  templateOptions: {
                    label: 'Phone number',
                    placeholder: 'Enter phone number',
                    required: true,
                    type: 'tel',
                  },
                },
              ],
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'sponsorEmail',
                  type: 'input',
                  templateOptions: {
                    label: 'Email',
                    placeholder: 'Enter email id',
                    required: true,
                    type: 'email',
                  },
                  validators: {
                    email: {
                      expression: (c: AbstractControl) =>
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          c.value
                        ),
                      message: (error: any, field: FormlyFieldConfig) =>
                        `${field.formControl?.value} is not a valid email address`,
                    },
                  },
                },
              ],
            },
            {
              className: 'field col-12',
              key: 'sponsorId',
              type: 'file',
              templateOptions: {
                placeholder: 'Sponsor Id proof',
                required: true,
                accept: '.pdf,.jpg,.png',
                description: 'Supported file formats: pdf, jpg, png',
              },
            },
            {
              className: 'field col-12',
              template: '<h3>Garage Incharge</h3>',
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'inchargeName',
                  type: 'input',
                  templateOptions: {
                    label: 'Name of the Garage incharge',
                    placeholder: 'Enter name',
                    required: true,
                  },
                },
                {
                  className: 'field col-6',
                  key: 'inchargePhone',
                  type: 'input',
                  templateOptions: {
                    label: 'Phone number',
                    placeholder: 'Enter phone number',
                    required: true,
                    type: 'tel',
                  },
                },
              ],
            },
            {
              fieldGroupClassName: 'field grid p-fluid',
              fieldGroup: [
                {
                  className: 'field col-6',
                  key: 'inchargeEmail',
                  type: 'input',
                  templateOptions: {
                    label: 'Email',
                    placeholder: 'Enter email id',
                    required: true,
                    type: 'email',
                  },
                  validators: {
                    email: {
                      expression: (c: AbstractControl) =>
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          c.value
                        ),
                      message: (error: any, field: FormlyFieldConfig) =>
                        `${field.formControl?.value} is not a valid email address`,
                    },
                  },
                },
              ],
            },
            {
              className: 'field col-12',
              key: 'inchargeIdProof',
              type: 'file',
              templateOptions: {
                placeholder: 'Oman Id proof',
                required: true,
                accept: '.pdf,.jpg,.png',
                description: 'Supported file formats: pdf, jpg, png',
              },
            },
          ],
        },
      ];
    }
  }

  populateFormWithServiceProviderDetails(res: any) {
    if (!this.isFormPopulated) {
      this.model.ownerName = res.ownerName;
      this.model.ownerPhone = res.ownerPhone;
      this.model.ownerEmail = res.ownerEmail;
      this.model.ownerAdhaar = res.ownerAdhaar || '';

      this.model.inchargeName = res.inchargeName;
      this.model.inchargePhone = res.inchargePhone;
      this.model.inchargeEmail = res.inchargeEmail;
      this.model.inchargeAdhaar = res.inchargeAdhaar || '';

      this.model.sponsorName = res.sponsorName || '';
      this.model.sponsorPhone = res.sponsorPhone || '';
      this.model.sponsorEmail = res.sponsorEmail || '';

      this.form.patchValue(this.model);
      this.isFormPopulated = true;
      this.formStateService1.setFormData('owner-details', this.model);
    }
  }



  loadFormData() {
    const savedData = this.formStateService.getFormData('owner-details');
    if (savedData) {
      this.model = { ...savedData };
      // Patch the form with saved data
      this.isFormPopulated = true
      this.form.patchValue(this.model);
    }
  }

  saveFormData() {
    const formData = { ...this.model };
    this.formStateService.setFormData('owner-details', formData);
  }

  submit() {
    if (this.form.valid) {
      this.saveFormData();
      const transformedDataOI = this.createFinalObject(this.form.value);
      this.formStateService.setOwnerInchargeDetails(transformedDataOI);

      // Store owner details separately
      const ownerDetails = {
        ownerName: this.model.ownerName,
        ownerPhoneNumber: this.model.ownerPhone,
        ownerEmailAddress: this.model.ownerEmail,
      };
      this.formStateService.setOwnerDetails(ownerDetails);

      const data = this.formStateService.getCombinedData();
      console.log('Combined data', data);
      this.nextSection.emit();
    }
  }

  createFinalObject(ownerInchargeData: any): any {
    return {
      sponsorName: ownerInchargeData.sponsorName || null,
      ownerName: ownerInchargeData.ownerName || null,
      ownerPhoneNumber: ownerInchargeData.ownerPhone || null,
      sponsorPhoneNumber: ownerInchargeData.sponsorPhone || null,
      sponsorEmailAddress: ownerInchargeData.sponsorEmail || null,
      ownerEmailAddress: ownerInchargeData.ownerEmail || null,
      garageInChargeName: ownerInchargeData.inchargeName,
      garageInchargeEmailAddress: ownerInchargeData.inchargeEmail,
      garageInChargePhoneNumber: ownerInchargeData.inchargePhone,
      documents: [
        {
          documentTypeId: 7, // Owner Id proof
          documentNumber: '',
          fileId: ownerInchargeData.ownerAdhaar || ownerInchargeData.sponsorId,
        },
        {
          documentTypeId: 8, // Incharge Id proof
          documentNumber: '',
          fileId:
            ownerInchargeData.inchargeAdhaar ||
            ownerInchargeData.inchargeIdProof,
        },
      ],
    };
  }
}
