import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ServiceProviderService } from '../../Service/Service-providers/service-provider.service';
import { TabComponent } from '../users/tab/tab.component';
import { FormStateService } from './add-sp-new-component/form-state.service';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrl: './service-provider.component.scss',
})
export class ServiceProviderComponent implements OnInit {
  showModal = false;
  showPage = true;
  submittedCount: number = 0;
  inReviewCount: number = 0;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  ref: DynamicDialogRef | undefined;
  activeIndex: number = 0;

  status!: number;

  display: boolean = false;

  @ViewChild('submittedTab') submittedTab!: TabComponent;
  @ViewChild('inReviewTab') inReviewTab!: TabComponent;
  @ViewChild('approvedTab') approvedTab!: TabComponent;
  @ViewChild('rejectedTab') rejectedTab!: TabComponent;

  constructor(
    public dialogService: DialogService,
    public messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private api: ServiceProviderService,
    private formStateService1: FormStateService
  ) { }
  ngOnInit(): void {
    this.fetchInitialData()
  }
  openModal() {
    // Open modal logic
    this.showModal = true
  }
  fetchInitialData(): void {
    const params = { status: this.activeIndex + 1 };

    this.api.getServiceProviderlist(params).subscribe((res: any) => {
      // Update counts for all tabs
      this.submittedCount = res.totalSubmittedRecord;
      this.inReviewCount = res.totalInReviewedRecord;
      this.approvedCount = res.totalApprovedRecord;
      this.rejectedCount = res.totalRejectedRecord;

      this.loadTabData(this.activeIndex, res.result.data, res.result.recordsFiltered);
    });
  }
  onTabChange(event: any) {
    const selectedIndex = event.index;
    this.activeIndex = selectedIndex;
    this.fetchTabData(selectedIndex);
  }
  fetchTabData(index: number) {
    const params = { status: index + 1 };

    this.api.getServiceProviderlist(params).subscribe((res: any) => {
      this.loadTabData(index, res.result.data, res.result.recordsFiltered);
    });
  }
  loadTabData(index: number, data: any[], totalRecords: number) {
    switch (index) {
      case 0:
        this.submittedTab.setData(data, totalRecords);
        break;
      case 1:
        this.inReviewTab.setData(data, totalRecords);
        break;
      case 2:
        this.approvedTab.setData(data, totalRecords);
        break;
      case 3:
        this.rejectedTab.setData(data, totalRecords);
        break;
      default:
        break;
    }
  }
  showAddSPPage() {
    this.showModal = true;
    this.showPage = false;
  }
  showModalW() {
    this.showModal = false
  }
  updateListOnPaymentSuccess(): void {
    this.fetchInitialData();
    this.showModal = false;
  }
}
