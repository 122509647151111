<div class="modal">
    <div class="modal-content">
        <h4>Do you want to Add a new services?</h4>
        <p>Once you approve,</p>
        <ul>
            <li>A new <strong>
                    Service
                </strong>
                will be addded.</li>
        </ul>
        <div class="modal-footer">
            <button class="cancel-button" (click)="onCancel()">Cancel</button>
            <button class="approve-button" (click)="onConfirm()">Approve</button>
        </div>
    </div>
</div>