<div class="flex justify-content-between flex-wrap mb-4" style="padding-top: 22px;">
  <div class="col-3">
    <span style="font-size: 23px; font-weight: 600;">Service Providers</span>
  </div>
  <div class="p-col-3" style="text-align:right; margin-right: 10px; cursor: pointer;" (click)="openModal()">
    <button type="button" class="add-button" pRipple>
      <i class="pi pi-plus" style="vertical-align: middle;"></i>
      <span style="margin-left: 8px;">Add Service Provider</span>
    </button>
  </div>
</div>
<p-tabView (onChange)="onTabChange($event)">
  <p-tabPanel [header]="'Submitted (' + submittedCount + ')'" pRipple>
    <app-service-provider-status #submittedTab [status]="1"></app-service-provider-status>
  </p-tabPanel>
  <p-tabPanel [header]="'In review (' + inReviewCount + ')'" pRipple>
    <app-service-provider-status #inReviewTab [status]="2"></app-service-provider-status>
  </p-tabPanel>
  <p-tabPanel [header]="'Approved (' + approvedCount + ')'" pRipple>
    <app-service-provider-status #approvedTab [status]="3"></app-service-provider-status>
  </p-tabPanel>
  <p-tabPanel [header]="'Rejected (' + rejectedCount + ')'" pRipple>
    <app-service-provider-status #rejectedTab [status]="4"></app-service-provider-status>
  </p-tabPanel>
</p-tabView>

<div>
  <app-add-sp *ngIf="showModal" (updateServiceProviderList)="updateListOnPaymentSuccess()"
    (closeDialog)="showModalW()"></app-add-sp>
</div>