import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomDialogComponent } from '../../../component/custom-dialog/custom-dialog.component';
import { ServiceProviderService } from '../../../Service/Service-providers/service-provider.service';
import { ActivatedRoute } from '@angular/router';
import { ViewerComponent } from '../../../component/viewer/viewer.component';
import { FormStateService } from '../add-sp-new-component/form-state.service';

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'app-service-providers-details-2',
  templateUrl: './service-providers-details-2.component.html',
  styleUrl: './service-providers-details-2.component.scss',
})
export class ServiceProvidersDetails2Component implements OnInit {
  serviceProviderName = '';
  imgPath = '';
  emailAddress = '';
  address = '';
  name = '';
  contact = '';
  status = '';
  businessType = '';
  submittedBy = '';
  email = '';
  gst = '';
  date = '';
  commencementYear = '';
  applicationId = '';
  planName = 'Basic';
  paidAmount = '₹299/-';
  serviceName: any;
  serviceProviderId: any;
  serviceprovider: any;
  userName = '';
  garageInChargeName = '';
  serviceProviderTechnicians: any = [];
  serviceProviderDocuments: any[] = [];
  selectedDocumentUrl: string | undefined;
  visible = false;
  visibleReject = false;
  visibleViewer = false;
  showDocumentViewer = false;
  garageInChargePhoneNumber = '';
  ownerEmailAddress = '';
  ownerPhoneNumber = '';
  applicantEmailAddress = '';
  showModal = false;
  idToEdit!: any;
  data: any;

  events: any;
  showAllServices: boolean = false;
  visibleHistory: boolean = false;
  reviewModal = false;
  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private api: ServiceProviderService,
    private formStateService: FormStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.serviceProviderId = params.get('serviceProviderId');
      this.formStateService.setServiceProviderId(this.serviceProviderId);
      this.formStateService.setUserId(this.serviceProviderId);
      this.getServiceProvidersDetails();
    });
  }
  refreshDetails() {
    this.closeApproveModal();
    this.getServiceProvidersDetails();
  }
  getServiceProviderDetailsById() {
    this.closeRejectModal();
    this.getServiceProvidersDetails();
  }
  getServiceProvidersDetails() {
    this.api
      .getServiceproviderById(this.serviceProviderId)
      .subscribe((res: any) => {
        this.cdr.detectChanges();
        this.serviceprovider = res;
        this.serviceProviderName = res.name;
        this.address = res.address;
        this.applicationId = res.applicationNumber;
        this.submittedBy = res.applicantName;
        this.gst = res.gstNumber || 'Not provided';
        this.commencementYear = res.yearOfEstablishment;
        this.contact = res.phoneNumber;
        this.emailAddress = res.ownerEmailAddress || 'Not available';
        this.status = res.status;
        this.serviceName = res.serviceOffers.map((x: any) => x.serviceName);
        this.userName =
          res.ownerEmailAddress + '....' || 'No Email Address provided';
        this.name = res.ownerName || res.sponsorName;
        this.businessType = res.businessType.name;
        this.garageInChargeName = res.garageInChargeName;
        this.garageInChargePhoneNumber = res.garageInChargePhoneNumber;
        this.ownerPhoneNumber = res.ownerPhoneNumber || res.sponsorPhoneNumber;
        this.applicantEmailAddress = res.applicantEmailAddress;
        this.date = res.createdDate;
        this.serviceProviderDocuments = res.serviceProviderDocuments;
        this.ownerEmailAddress =
          res.ownerEmailAddress || res.sponorEmailAddress;

        const domain = this.api.api;
        const filePath =
          res.serviceProviderGalleries.length > 0
            ? res.serviceProviderGalleries[0].filePath
            : '';
        this.imgPath = `${domain}/${filePath}`;
        console.log('Image-path:',JSON.stringify(this.imgPath))

        this.serviceProviderTechnicians = res.serviceProviderTechnicians.map(
          (technician: any) => {
            return {
              technicianId: technician.technicianId,
              technicianName: technician.technicianName,
              noOfTechnician: technician.noOfTechnician,
            };
          }
        );

        console.log(this.serviceprovider);
      });
  }

  getTotalTechnicians(): number {
    if (
      this.serviceprovider &&
      this.serviceprovider.serviceProviderTechnicians
    ) {
      return this.serviceprovider.serviceProviderTechnicians
        .map((tech: any) => tech.noOfTechnician)
        .reduce((total: number, num: number) => total + num, 0);
    }
    return 0;
  }

  get truncatedEmail(): string {
    const maxLength = 6;
    if (this.applicantEmailAddress.length > maxLength) {
      return this.applicantEmailAddress.substring(0, maxLength) + '.....';
    } else {
      return this.applicantEmailAddress;
    }
  }

  subDomainName() {
    // set sub-domain name
    const subDomainName = this.serviceProviderName!.toLowerCase().replace(
      /\s+/g,
      ''
    );
    this.formStateService.setSubDomainName(subDomainName);
  }

  get truncatedServiceProviderName(): string {
    const maxLength = 50;
    if (this.serviceProviderName.length > maxLength) {
      return this.serviceProviderName.substring(0, maxLength) + '....';
    } else {
      return this.serviceProviderName;
    }
  }
  viewAddressProof() {
    const addressProofDocument = this.serviceProviderDocuments.find(
      (doc) => doc.documentTypeId === 5
    );
    if (addressProofDocument) {
      this.selectedDocumentUrl = `${this.api.api}/${addressProofDocument.filePath}`;
      console.log('Selected Document URL:', this.selectedDocumentUrl);
      this.showDocumentViewer = true;
      this.visibleViewer = true;
    } else {
      console.error('Address proof document with documentTypeId 5 not found.');
    }
  }

  viewOwnerIdProof() {
    const ownerIdProofDocument = this.serviceProviderDocuments.find(
      (doc) => doc.documentTypeId === 7
    );
    if (ownerIdProofDocument) {
      this.selectedDocumentUrl = `${this.api.api}/${ownerIdProofDocument.filePath}`;
      console.log('Selected Document URL:', JSON.stringify(this.selectedDocumentUrl));
      this.showDocumentViewer = true;
      this.visibleViewer = true;
    } else {
      console.error('Owner ID proof document with documentTypeId 7 not found.');
    }
  }

  viewInchargeIdProof() {
    const inchargeIdProofDocument = this.serviceProviderDocuments.find(
      (doc) => doc.documentTypeId === 8
    );
    if (inchargeIdProofDocument) {
      this.selectedDocumentUrl = `${this.api.api}/${inchargeIdProofDocument.filePath}`;
      this.showDocumentViewer = true;
      this.visibleViewer = true;
    } else {
      console.error('Incharge ID proof document with documentTypeId 8 not found.');
    }
  }
  

  viewCOIProof() {
    const coiProofDocument = this.serviceProviderDocuments.find(
      (doc) => doc.documentTypeId === 6
    );
    if (coiProofDocument) {
      this.selectedDocumentUrl = `${this.api.api}/${coiProofDocument.filePath}`;
      this.showDocumentViewer = true;
      console.log('Selected Document URL:', this.selectedDocumentUrl);
      this.visibleViewer = true; // Ensure the modal is visible
    } else {
      console.error(
        'Certificate of Incorporation proof document with documentTypeId 6 not found.'
      );
    }
  }

  closeViewer() {
    this.visibleViewer = false;
    this.selectedDocumentUrl = undefined; // Clear the document URL when the viewer is closed
    this.showDocumentViewer = false;
  }
  isPdf(url: string | undefined): boolean {
    return url ? url.toLowerCase().endsWith('.pdf') : false;
  }
  approve() {
    console.log('Approve button is clicked');
    this.visible = true;
    this.subDomainName();
  }
  viewer() {
    this.visibleViewer = true;
  }

  showModalW() {
    this.showModal = false;
    this.getServiceProvidersDetails();
  }
  openEditModal(id: any) {
    this.idToEdit = id;
    this.showModal = true;
    console.log(this.idToEdit);
  }

  reject() {
    this.visibleReject = true;
  }

  closeApproveModal(): void {
    this.visible = false;
  }

  closeRejectModal(): void {
    this.visibleReject = false;
  }

  displayHistory() {
    this.visibleHistory = true;
    this.api.getHistory(this.serviceProviderId).subscribe((data: any) => {
      console.log(data);
      this.events = data;
    });
  }

  // Getter to determine which services to display based on the showAllServices flag
  get displayedServices(): string[] {
    return this.showAllServices
      ? this.serviceName
      : this.serviceName?.slice(0, 4);
  }

  toggleShowAllServices() {
    this.showAllServices = !this.showAllServices;
  }
  openReviewModal() {
    this.reviewModal = true;
  }
  closeReviewModal() {
    this.reviewModal = false;
  }
  markForReview() {
    this.reviewModal = false;
  }
}
